
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getInstance, Item } from "@/services/ItemService";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";
import { clone } from "@/utils/object";
import { http } from "@/api/rest";

export default defineComponent({
  setup() {
    const categoria = ref<Categoria>();
    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
        somenteAtivas: true,
      });
      categorias.value = data;
    });

    const filter = reactive({
      rows: 50,
      page: 0,
      sortField: "e.qtd",
      sortOrder: -1,
      totalRecords: 0,
      q: "",
      categorias: null,
    });

    const totais = ref<any>();
    const totaisCategorias = ref<any[]>();

    const loading = ref(false);
    const result = ref<any>([]);
    async function consultar() {
      loading.value = true;
      totais.value = undefined;
      totaisCategorias.value = undefined;
      try {
        const params = clone(filter);
        const resp = (
          await http.get("api/pa/estoque", {
            params,
          })
        ).data;
        result.value = resp.data.map((e: any) => {
          e.imagens = e.imagens ? JSON.parse(e.imagens) : null;
          return e;
        });
        filter.totalRecords = resp.total;
        totais.value = resp.aggregation;
        totaisCategorias.value = JSON.parse(resp.aggregation?.categorias || "[]");
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => consultar());

    return {
      categoria,
      categorias,
      changeCategoria() {
        // @ts-ignore
        filter.categorias = categoria.value
          ? [parseInt(`${categoria.value?.id}`)]
          : null!;
        consultar();
      },

      result,
      totais,
      totaisCategorias,
      filter,
      loading,
      consultar,
      onPage(evt: any) {
        filter.rows = evt.rows;
        filter.page = evt.page;
        consultar();
      },
      onSort(evt: any) {
        filter.sortField = evt.sortField;
        filter.sortOrder = evt.sortOrder;
        consultar();
      },

      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
