<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Meu Estoque</h5>
      </template>

      <template #right>
        <div class="p-d-flex p-flex-wrap">
          <p-dropdown
            v-model="categoria"
            required
            :options="categorias"
            optionLabel="nome"
            placeholder="Todas as Categorias"
            data-key="id"
            showClear
            style="min-width: 200px"
            @change="changeCategoria"
            class="p-mr-2"
          />
          <span class="p-d-inline-flex p-mr-2">
            <p-inputtext
              v-model="filter.q"
              @keypress.enter="consultar"
              placeholder="Pesquisar..."
            />
            <p-button icon="pi pi-search" @click="consultar" />
          </span>
        </div>
      </template>
    </p-toolbar>

    <p-datatable
      :value="result"
      :paginator="true"
      :rows="filter.rows"
      :lazy="true"
      :totalRecords="filter.totalRecords"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      responsiveLayout="scroll"
      stripedRows
      class="p-col-12"
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro encontrado!</div>
      </template>

      <p-column field="i.codigo" header="Código" :sortable="true">
        <template #body="{ data }">{{ data.codigo }}</template>
      </p-column>
      <p-column field="i.nome" header="Produto" :sortable="true">
        <template #body="{ data }">
          <div class="p-d-flex p-ai-center">
            <img
              :src="getUrlImagem(data)"
              :alt="data.nome"
              class="p-mr-2"
              style="min-width: 40px; width: 40px; min-height: 40px"
            />
            {{ data.nome }}
          </div>
        </template>
      </p-column>
      <p-column
        field="e.qtd"
        header="Estoque"
        :sortable="true"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd) }}</template>
      </p-column>
      <p-column
        field="e.qtd_pedidos"
        header="Bloqueado"
        :sortable="true"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd_pedidos) }}</template>
      </p-column>
      <p-column
        field="e.qtd_disponivel"
        header="Disponível"
        :sortable="true"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd_disponivel) }}</template>
      </p-column>
    </p-datatable>

    <p-datatable
      v-if="totaisCategorias?.length"
      :value="totaisCategorias"
      :paginator="false"
      responsiveLayout="stack"
      stripedRows
      class="p-col-12"
    >
      <template #header>Estoque por Categoria</template>
      <p-column field="nome" header="Categoria" />
      <p-column
        field="qtd"
        header="Estoque"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd) }}</template>
      </p-column>
      <p-column
        field="qtd_pedidos"
        header="Bloqueado"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd_pedidos) }}</template>
      </p-column>
      <p-column
        field="qtd_disponivel"
        header="Disponível"
        style="text-align: right; padding-right: 20px !important"
      >
        <template #body="{ data }">{{ parseInt(data.qtd_disponivel) }}</template>
      </p-column>
    </p-datatable>

    <div class="p-col-12">
      <div class="total-info">
        <span>Total em Custo:</span>
        <span>
          <b>{{ $formatCurrency(parseFloat(totais?.custo) || 0) }}</b>
        </span>
      </div>
    </div>
    <div class="p-col-12">
      <div class="total-info">
        <span>Total Valor Venda Afiliado:</span>
        <span>
          <b>{{ $formatCurrency(parseFloat(totais?.venda_afiliado) || 0) }}</b>
        </span>
      </div>
    </div>
    <div class="p-col-12">
      <div class="total-info">
        <span>Total Valor Venda Consumidor:</span>
        <span>
          <b>{{ $formatCurrency(parseFloat(totais?.venda) || 0) }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getInstance, Item } from "@/services/ItemService";
import {
  getInstance as getCategoriaSerive,
  Categoria,
} from "@/services/CategoriaService";
import { clone } from "@/utils/object";
import { http } from "@/api/rest";

export default defineComponent({
  setup() {
    const categoria = ref<Categoria>();
    const categorias = ref<Categoria[]>([]);
    onMounted(async () => {
      const { data } = await getCategoriaSerive().consultar({
        first: 100,
        page: 1,
        sortField: "id",
        sortOrder: "ASC",
        somenteAtivas: true,
      });
      categorias.value = data;
    });

    const filter = reactive({
      rows: 50,
      page: 0,
      sortField: "e.qtd",
      sortOrder: -1,
      totalRecords: 0,
      q: "",
      categorias: null,
    });

    const totais = ref<any>();
    const totaisCategorias = ref<any[]>();

    const loading = ref(false);
    const result = ref<any>([]);
    async function consultar() {
      loading.value = true;
      totais.value = undefined;
      totaisCategorias.value = undefined;
      try {
        const params = clone(filter);
        const resp = (
          await http.get("api/pa/estoque", {
            params,
          })
        ).data;
        result.value = resp.data.map((e: any) => {
          e.imagens = e.imagens ? JSON.parse(e.imagens) : null;
          return e;
        });
        filter.totalRecords = resp.total;
        totais.value = resp.aggregation;
        totaisCategorias.value = JSON.parse(resp.aggregation?.categorias || "[]");
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => consultar());

    return {
      categoria,
      categorias,
      changeCategoria() {
        // @ts-ignore
        filter.categorias = categoria.value
          ? [parseInt(`${categoria.value?.id}`)]
          : null!;
        consultar();
      },

      result,
      totais,
      totaisCategorias,
      filter,
      loading,
      consultar,
      onPage(evt: any) {
        filter.rows = evt.rows;
        filter.page = evt.page;
        consultar();
      },
      onSort(evt: any) {
        filter.sortField = evt.sortField;
        filter.sortOrder = evt.sortOrder;
        consultar();
      },

      getUrlImagem(item: Item) {
        return (
          getInstance().getUrlImagem(item) ||
          require("@/assets/shop-placeholder.png")
        );
      },
    };
  },
});
</script>

<style scoped>
.total-info {
  text-align: center;
}
.total-info span:first-child {
  display: inline-block;
  margin-right: 5px;
  min-width: 200px;
  text-align: right;
}
</style>